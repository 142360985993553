import TextureLoader from './TextureLoader';
import GLTFLoader from './GLTFLoader';
// import GLTFLoader from './GLTFLoader';

export default class ResourceBatch
{
	resource_loaders: any;
	batch_name: any;

	constructor(batch_name?: any)
	{
		this.resource_loaders = [];
		this.batch_name = batch_name || "unnamed batch";
	}

	add_texture(resource_id: any, url: any, file_name: any)
	{
		this.resource_loaders.push(new TextureLoader(resource_id, url, file_name));
	}

	add_gltf(resource_id: any, url: any, file_name: any)
	{
		this.resource_loaders.push(new GLTFLoader(resource_id, url, file_name));
	}

	load()
	{
		for(let i=0; i< this.resource_loaders.length; i++)
		{
			this.resource_loaders[i].load();
		}
	}

	get loading_finished()
	{
		let finished = true;
		for(let i=0; i< this.resource_loaders.length; i++)
		{
			finished = finished && this.resource_loaders[i].has_finished;
		}
		return finished;
	}

	get has_errors()
	{
		let has_error = false;
		for(let i=0; i< this.resource_loaders.length; i++)
		{
			has_error = has_error || this.resource_loaders[i].has_error;
		}
		return has_error;
	}

	print_errors()
	{
		console.error("Batch <"+this.batch_name+"> could not load successfully")
		for(let i=0; i< this.resource_loaders.length; i++)
		{
			if(this.resource_loaders[i].has_error)
				this.resource_loaders[i].print_error();
		}
	}

	get_progress()
	{
		let progress = 0;
		for(let i=0; i< this.resource_loaders.length; i++)
		{
			progress+=this.resource_loaders[i].progress;
		}
		return progress/this.resource_loaders.length;
	}
}
