import { AssetsManager } from "@babylonjs/core/Misc/assetsManager";
import AbstractLoader from './AbstractLoader';
import SceneManager from '../scene_manager';
import ResourceContainer from '../ResourceContainer';

export default class TextureLoader extends AbstractLoader
{
	loader: any;

	constructor(resource_id: any, rootUrl: any, fileName: any)
	{
		super(resource_id, rootUrl, fileName);
		this.loader = new AssetsManager(SceneManager.current);
	}

	load() {
		let ctx = this;

		let meshTask = this.loader.addTextureTask(ctx.resource_id, this.url + this.file_name);

		meshTask.onSuccess = function (task: any) {
			ResourceContainer.set_resource(ctx.resource_id, task.texture);
			ctx.__update_progress(1);
			ctx.__loading_ended()
		}

		meshTask.onError = function (task: any, message: any, exception: any) {
			console.log(task);
			console.log(message, exception);
			ctx.__set_error(message);
			ctx.__loading_ended()
		}

		this.loader.load();
	}
}
