import SceneManager from './scene_manager';

export class RenderLoop {
  engine: any;

  constructor(engine: any) {
      this.engine = engine;
  }

  start() {
    // Render every frame
    this.engine.runRenderLoop(this.update);
  }

  stop() {
    this.engine.stopRenderLoop(this.update);
  }

  update() {
    SceneManager.current.render();
    // console.log("sd")
    //hacer el update de graphics, etd
  }
}
