import '../assets/index.css';
import "@babylonjs/core/Debug/debugLayer"; // Augments the scene with the debug methods
import "@babylonjs/inspector"; // Injects a local ES6 version of the inspector to prevent
                               // automatically relying on the none compatible version

import { Engine } from "@babylonjs/core/Engines/engine";
import { Scene } from "@babylonjs/core/scene";
import { Vector3, Color3 } from "@babylonjs/core/Maths/math";
import { HemisphericLight } from "@babylonjs/core/Lights/hemisphericLight";
import { Mesh } from "@babylonjs/core/Meshes/mesh";
import { Sound } from "@babylonjs/core/Audio/sound";

import { RenderLoop } from "../core/render_loop";
import SceneManager from "../core/scene_manager";

// Required side effects to populate the Create methods on the mesh class.
// Without this, the bundle would be smaller but the createXXX methods from mesh would not be accessible.
import "@babylonjs/core/Meshes/meshBuilder";
import { ArcRotateCamera } from '@babylonjs/core/Cameras/arcRotateCamera';
import { StandardMaterial } from '@babylonjs/core/Materials/standardMaterial';
import { Camera } from '@babylonjs/core/Cameras/camera';
import { PointerEventTypes } from '@babylonjs/core/Events/pointerEvents';

export default class OHZIExampleApplication {
  static engine: any;
  static canvas: any;

  static init() {
    // Get the canvas element from the DOM.
    this.canvas = document.getElementById("renderCanvas") as HTMLCanvasElement;

    // Associate a Babylon Engine to it.
    this.engine = new Engine(this.canvas);

    // Create our first scene.
    var scene = new Scene(this.engine);
    SceneManager.current = scene;
  }

  static start() {
    // This creates and positions a free camera (non-mesh)
    // var camera = new FreeCamera("camera1", new Vector3(0, 60, -30), SceneManager.current);

    var camera = new ArcRotateCamera("camera1", 3.14 / 4, 3.14 / 4, 60, new Vector3(0, 10, 0), SceneManager.current);

    camera.mode = Camera.ORTHOGRAPHIC_CAMERA;

    let zoom = 40;
    camera.orthoTop = this.engine.getRenderHeight(true) / zoom;
    camera.orthoBottom = -this.engine.getRenderHeight(true) / zoom;
    camera.orthoLeft = -this.engine.getRenderWidth(true) / zoom;
    camera.orthoRight = this.engine.getRenderWidth(true) / zoom;

    // This targets the camera to scene origin
    camera.setTarget(Vector3.Zero());

    // This attaches the camera to the canvas
    camera.attachControl(this.canvas, true);

    // This creates a light, aiming 0,1,0 - to the sky (non-mesh)
    var light = new HemisphericLight("light1", new Vector3(0, 1, 0), SceneManager.current);

    // Default intensity is 1. Let's dim the light a small amount
    light.intensity = 0.7;

    // Create a grid material
    // var material = new GridMaterial("grid", SceneManager.current);

    // Our built-in 'sphere' shape. Params: name, subdivs, size, scene
    var sphere_1 = Mesh.CreateSphere("sphere1", 32, 4, SceneManager.current);
    var sphere_2 = Mesh.CreateSphere("sphere1", 32, 4, SceneManager.current);

    // Move the sphere upward 1/2 its height
    sphere_1.position.x = 4;
    sphere_1.position.y = -4;
    sphere_2.position.y = 5;
    sphere_2.position.x = 2;

    // var shaderMaterial = new ShaderMaterial("shader", SceneManager.current,
    //   {
    //     vertex: 'basic_color/basic_color',
    //     fragment: 'basic_color/basic_color'
    //   },
    //   {
    //     attributes: ["position", "normal", "uv"],
    //     uniforms: ["world", "worldView", "worldViewProjection", "view", "projection"]
    //   });

    // var renderTarget = new RenderTargetTexture("depth", 200, SceneManager.current, false, true);
    // renderTarget.renderList = SceneManager.current.meshes;

    var material_1 = new StandardMaterial("myMaterial", SceneManager.current);
    material_1.diffuseColor = new Color3(1, 0, 1);
    material_1.specularColor = new Color3(0.5, 0.6, 0.87);
    material_1.emissiveColor = new Color3(1, 0, 0);
    material_1.ambientColor = new Color3(0.23, 0.98, 0.53);

    // Affect a material
    sphere_1.material = material_1;

    var material_2 = new StandardMaterial("myMaterial", SceneManager.current);
    material_2.diffuseColor = new Color3(1, 0, 1);
    material_2.specularColor = new Color3(0.5, 0.6, 0.87);
    material_2.emissiveColor = new Color3(0, 1, 0);
    material_2.ambientColor = new Color3(0.23, 0.98, 0.53);
    sphere_2.material = material_2;
    // shaderMaterial.setTexture("texture2", renderTarget);

    // Our built-in 'ground' shape. Params: name, width, depth, subdivs, scene
    // var ground = Mesh.CreateGround("ground1", 6, 6, 2, scene);

    // Affect a material
    // ground.material = material;

    let orto = SceneManager.current.getNodeByName('ortoedro')
    let wall_1 = SceneManager.current.getNodeByName('front_mask')
    let wall_2 = SceneManager.current.getNodeByName('back_mask')

    orto.position.y = -10;
    console.log(wall_1)

    // var box = Mesh.CreateBox("box", 10, SceneManager.current);

    sphere_1.renderingGroupId = 1;
    sphere_2.renderingGroupId = 1;
    wall_1.renderingGroupId = 0; // force render after plane
    wall_2.renderingGroupId = 0; // force render after plane

    // box.registerBeforeRender(() => { scene._engine.setColorWrite(false) });
    // box.registerAfterRender(() => { scene._engine.setColorWrite(true) });

    SceneManager.current.autoClearDepthAndStencil = false;
    SceneManager.current.setRenderingAutoClearDepthStencil(0, false);
    SceneManager.current.setRenderingAutoClearDepthStencil(1, false);

    this.engine.setStencilBuffer(true);
    this.engine.setStencilFunctionReference(1);
    let engine = this.engine;

    SceneManager.current.onBeforeRenderObservable.add(() => {
      // clear depth
      this.engine.clear(undefined, false, true, false);
    })

    wall_1.onBeforeRenderObservable.add(function () {
      engine.setStencilMask(0xFF);
      engine.setStencilFunctionReference(1);
      engine.setStencilOperationDepthFail(Engine.REPLACE);
      engine.setColorWrite(false);
      engine.setDepthWrite(false);
    });

    wall_1.onAfterRenderObservable.add(() => {
      engine.setColorWrite(true);
      engine.setDepthWrite(true);
      engine.setStencilMask(0x00);
    });

    wall_2.onBeforeRenderObservable.add(function () {
      engine.setStencilMask(0xFF);
      engine.setStencilFunctionReference(2);
      engine.setStencilOperationDepthFail(Engine.REPLACE);
      engine.setColorWrite(false);
      engine.setDepthWrite(false);
    });

    wall_2.onAfterRenderObservable.add(() => {
      engine.setColorWrite(true);
      engine.setDepthWrite(true);
      engine.setStencilMask(0x00);
    });

    sphere_1.onBeforeRenderObservable.add(function () {
      engine.setStencilMask(0xFF); // write disabled
      engine.setStencilFunction(Engine.EQUAL);
      engine.setStencilFunctionReference(2);
    });

    sphere_1.onAfterRenderObservable.add(() => {
      engine.setStencilMask(0x00); // write enabled (need before clear)
      engine.setStencilFunction(Engine.ALWAYS);
      //clear stencil
      engine.clear(undefined, false, false, true);
    });

    sphere_2.onBeforeRenderObservable.add(function () {
      engine.setStencilMask(0xFF); // write disabled
      engine.setStencilFunction(Engine.EQUAL);
      engine.setStencilFunctionReference(1);
    });

    sphere_2.onAfterRenderObservable.add(() => {
      engine.setStencilMask(0x00); // write enabled (need before clear)
      engine.setStencilFunction(Engine.ALWAYS);
      //clear stencil
      engine.clear(undefined, false, false, true);
    });

    let render_loop = new RenderLoop(this.engine);

    render_loop.start();
    OHZIExampleApplication.handle_sounds(camera);

    // SceneManager.current.debugLayer.show();
  }

  static handle_sounds(camera: ArcRotateCamera) {
    var can_play = [true, true, true, true, true];

    var notes: Sound[] = [];

    SceneManager.current.onPointerObservable.add(function () {
      play_random_sound(); //SceneManager.current.pointerX, SceneManager.current.pointerY
    }, PointerEventTypes.POINTERMOVE);

    var play_random_sound = () => {
      var camera_position_x = Math.abs(Math.floor(camera.position.x));

      if (camera_position_x > 63 && camera_position_x < 67 && can_play[0]) {
        play_sound(notes[0]);

        reset_can_play()
        can_play[0] = false
      }

      if (camera_position_x > 47 && camera_position_x < 52 && can_play[1]) {
        play_sound(notes[1]);

        reset_can_play()
        can_play[1] = false;
      }

      if (camera_position_x > 26 && camera_position_x < 31 && can_play[2]) {
        play_sound(notes[2]);

        reset_can_play()
        can_play[2] = false;
      }

      if (camera_position_x > 0 && camera_position_x < 5 && can_play[3]) {
        play_sound(notes[3]);

        reset_can_play()
        can_play[3] = false;
      }

      console.log(Math.floor(camera.position.x), (Math.floor(camera.position.z)))

    };

    var reset_can_play = () => {
      for (var i = 0; i < can_play.length; i++) {
        can_play[i] = true;
      }
    }

    var play_sound = (note: Sound) => {
      note.setVolume(0.2);
      note.play();
    }

    var koto_do = new Sound("do", "assets/sounds/koto_do.mp3", SceneManager.current,
      soundReady, { loop: false });
    notes.push(koto_do);

    var koto_re2 = new Sound("re2", "assets/sounds/koto_re2.mp3", SceneManager.current,
      soundReady, { loop: false });
    notes.push(koto_re2);

    var koto_fa = new Sound("fa", "assets/sounds/koto_fa.mp3", SceneManager.current,
      soundReady, { loop: false });
    notes.push(koto_fa);

    var koto_sol = new Sound("sol", "assets/sounds/koto_sol.mp3", SceneManager.current,
      soundReady, { loop: false });
    notes.push(koto_sol);

    var koto_do_high = new Sound("do_high", "assets/sounds/koto_do_high.mp3", SceneManager.current,
      soundReady, { loop: false });
    notes.push(koto_do_high);

    var soundsReady = 0;

    function soundReady() {
      soundsReady++;
      if (soundsReady === 21) {

      }
    }
  }

}
