import ResourceBatch from './core/resource_loader/ResourceBatch';
import OHZIExampleApplication from './modules/ohzi_example_application';

OHZIExampleApplication.init();

let batch = new ResourceBatch();

// batch.add_texture("worley_noise_texture1", "./assets/textures/", "noise.png");
// batch.add_gltf("model_cloud", "./assets/models/", "business_cloud.glb");
batch.add_gltf("ortoedro", "./assets/models/", "ortoedro.glb");

batch.load();

let check_resource_loading = () => {
  if (batch.loading_finished) {
    if (batch.has_errors) {
      batch.print_errors();
    }
    else {
      // this.view_api.resource_loading_completed();
      // this.view_api.start();
      OHZIExampleApplication.start();
    }
  }
  else {
    setTimeout(check_resource_loading, 200);
  }
}

check_resource_loading();
