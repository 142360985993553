// import EventManager from 'js/core/EventManager';

export default class ResourceContainer
{
	static resources: { [key: string]: any; } = {}

	constructor()
	{
		// this.resources = {};
	}

	static set_resource(name: string, resource: any)
	{
		if(this.resources[name] === undefined)
		{
			this.resources[name] = resource;
			// EventManager.fire_resource_loaded({name: name, value: resource});
		}
		else
		{
			console.error("Resource " + name + " already exists", this.resources[name])
		}

	}

	static get_resource(name: string)
	{
		return this.resources[name];
	}

}
