import { AssetsManager } from "@babylonjs/core/Misc/assetsManager";
import AbstractLoader from './AbstractLoader';
import SceneManager from '../scene_manager';
import { SceneLoader } from '@babylonjs/core/Loading/sceneLoader';

export default class GLTFLoader extends AbstractLoader
{
	loader: any;

	constructor(resource_id: any, rootUrl: any, fileName: any) {
		super(resource_id, rootUrl, fileName);
		this.loader = new AssetsManager(SceneManager.current);
	}

	load() {
		let ctx = this;

		SceneLoader.Append(this.url, this.file_name, SceneManager.current, (container) => {
			console.log(container)
			ctx.__update_progress(1);
			ctx.__loading_ended()
		})

		// SceneLoader.LoadAssetContainerAsync(this.url, this.file_name, SceneManager.current).then((container) => {
		// 	console.log(container)
		// })


		// let meshTask = this.loader.addMeshTask(ctx.resource_id, '', this.url, this.file_name);

		// meshTask.onSuccess = function (task: any) {
		// 	ResourceContainer.set_resource(ctx.resource_id, task.texture);
		// 	console.log(task)
		// 	ctx.__update_progress(1);
		// 	ctx.__loading_ended()
		// }

		// meshTask.onError = function (task: any, message: any, exception: any) {
		// 	console.log(message, exception);
		// 	ctx.__set_error(message);
		// 	ctx.__loading_ended()
		// }

		// this.loader.load();
	}
}
