export default class AbstractLoader
{
	progress: any;
	resource_id: any;
	url: any;
	file_name: any;
	has_finished: any;
	has_error: any;
	error_message: any;

	constructor(resource_id: any, url: any, file_name: any)
	{
		this.progress = 0;
		this.resource_id = resource_id;
		this.url = url;
		this.file_name = file_name;

		this.has_finished = false;
		this.has_error = false;
		this.error_message = "none";
	}

	__update_progress(value: any)
	{
		this.progress = value;
	}
	__loading_ended()
	{
		this.has_finished = true;
	}
	__set_error(message: any)
	{
		this.has_error = true;
		this.error_message = message;
	}

	print_error()
	{
		console.error("Error while loading "+this.resource_id+"\n\t path: "+this.url+this.file_name+"\n\t\t"+this.error_message);
	}
}
